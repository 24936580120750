import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <Home />
    </div>
  );
}

export default App;
