import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import showVideo from "../../assets/4k-abstract-white-particle-wave-bokeh-background-2023-01-03-10-03-40-utc.mp4";
import womenBreathing from "../../assets/women-breathing.png";
import contactUsBackground from "../../assets/contact-us-background.png";
import contactUsBackgroundMobile from "../../assets/contact-us-background-mobile.png";
import classes from "./index.module.css";
import Footer from "../../components/Footer/Footer";
function Home() {
  return (
    <>
      <NavBar />
      <div className={classes.HeaderContainer}>
        <video
          src={showVideo}
          type="video/mp4"
          playsInline
          autoPlay
          muted
          loop="loop"
        />
        <div className={classes.Tagline}>
          <div className={classes.TaglineText}>Building Revolutionary Oxygen Nanotherapeutics</div>
          <div className={classes.TaglineBody}>Introducing the first non-invasive nanofluid breathing aid for respiratory distress and advanced regenerative biomaterials for superior topical oxygen delivery – all powered by our nanotechnology platform</div>
          <a href="#contact-us"><button className={classes.TaglineButton}>Contact Us</button></a>
        </div>
      </div>
      <div className={classes.Introduction}>
        <img className={classes.WomenBreathing} src={womenBreathing} alt="women-breathing" />
        <div className={classes.IntroductionText}>
          <div className={classes.IntroductionTextHeader}>SWAZA-1 is the first non-invasive nanofluid breathing aid that enables dynamic gas exchange across fluid and fibrotic barriers at the lung surface</div>
          <div className={classes.IntroductionTextBody}>Our patented technology uses safe ingredients, is simple to administer, and is stable at room temperature.</div>
        </div>
        <img className={classes.ContactUsBackground} src={contactUsBackground} alt="contact-us-background" />
        <img className={classes.ContactUsBackgroundMobile} src={contactUsBackgroundMobile} alt="contact-us-background" />

        <div id="contact-us" className={classes.ContactUs}>
          <div className={classes.ContactUsHeader}>Contact Us</div>
          <div className={classes.ContactUsForm}>
            <form id="contact-us-form" action="https://formsubmit.co/media@swaza.life" method="POST">
              <input
                type="hidden"
                name="_subject"
                value="Email from Swaza website !"
              />
              <input
                type="hidden"
                name="_next"
                value="https://www.swaza.life/"
              />
              <input
                type="hidden"
                name="_captcha"
                value="true"
              />
              <div className={classes.VerticalFormGroup}>
                <div className={classes.FormGroup}>
                  <label for="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                  />
                </div>
                <div className={classes.FormGroup}>
                  <label for="email">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <div className={classes.FormGroup}>
                <label for="message">Message</label>
                <textarea
                  form="contact-us-form"
                  name="message"
                  id="message"
                  placeholder="Enter your message"
                ></textarea>
              </div>
              <button type="submit" className={classes.FormSubmit}>Submit</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
