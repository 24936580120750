import React from 'react'
import classes from "./PageHeader.module.css";

function PageHeader({ text, image }) {
    return (
        <div className={classes.PageHeaderContainer}>
            <img className={classes.PageHeaderImage} src={image} alt="header" />
            <div className={classes.Header}>
                <div className={classes.HeaderText}>{text}</div>
            </div>
        </div>
    )
}

export default PageHeader;