import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import JayakumarRajadas from "../../assets/Headshot/Jayakumar Rajadas.png";
import Niki from "../../assets/Headshot/Niki Santo.png";
import Paul from "../../assets/Headshot/paul.png"
import Artem from "../../assets/Headshot/Artem.png"
import Argie from "../../assets/Headshot/Argie Headshot.png"
import Naveen from "../../assets/Headshot/Jayakumar Naveen.png"
import Nicole from "../../assets/Headshot/Nicole Jones Headshot.png"
import Shanielle from "../../assets/Headshot/Shanielle Headshot.png"
import Anastassios from "../../assets/Headshot/anastassios retzios.png"
import Kyle from "../../assets/Headshot/kylebrewer.png"
import Douglas from "../../assets/Headshot/lawyer_douglas_park.png"
import Michael from "../../assets/Headshot/michael snyder.png"
import Prentice from "../../assets/Headshot/prentice.png"
import Robert from "../../assets/Headshot/robert.png"

import InvestorsImage from "../../assets/investors.png"
import InvestorsImageMobile from "../../assets/investors-mobile.png"
import EmailVector from "../../assets/EmailVector.svg"
import CollegeVector from "../../assets/CollegeVector.svg"
import classes from "./AboutUs.module.css";
import Footer from "../../components/Footer/Footer";
const team = [
    {
        image: JayakumarRajadas,
        name: "Prof. Jayakumar Rajadas, PhD",
        college: "Stanford Medical School",
        role: "Co-Founder & Chief Scientist",
        email: "jay@swaza.life"
    },
    {
        image: Niki,
        name: "Niki V. Santo",
        college: "Harvard University | University of Cambridge",
        role: "Co-Founder & CEO",
        email: "niki@swaza.life"
    },
    {
        image: Artem,
        name: "Dr. Artem Trotsyuk, PhD",
        college: "Stanford Medical School",
        role: "Chief Technical Officer",
        email: "artem@swaza.life"
    },
    {
        image: Anastassios,
        name: "Dr. Anastassios Retzios, PhD",
        college: "University of Edinburgh | University of Southern California",
        role: "Director of Regulatory and Clinical Affairs",
        email: "a.retzios@swaza.life"
    },
    {
        image: Robert,
        name: "Dr. Robert Lum, PhD",
        college: "UC Berkeley | MIT",
        role: "Chemistry Manufacturing and Controls",
        email: "r.lum@swaza.life"
    },
    {
        image: Kyle,
        name: "Kyle Brewer, PhD",
        college: "UT Southwestern Medical Center/Stanford Medical School",
        role: "Senior Scientist",
        email: "kyle.brewer@swaza.life"
    },
    {
        image: Nicole,
        name: "Nicole Jones",
        college: "",
        role: "Research Assistant",
        email: "lab@swaza.life"
    },
    {
        image: Douglas,
        name: "Douglas Park, JD, PhD",
        college: "Harvard University | Michigan Law | Stanford GSB",
        role: "General Counsel",
        email: ""
    },
    {
        image: Shanielle,
        name: "Shanielle Qim Caneda, JD",
        college: "Silliman University Law",
        role: "Project Manager",
        email: "s.caneda@swaza.life"
    },
    {
        image: Argie,
        name: "Argie Jabanes",
        college: "",
        role: "Finance",
        email: "accounts@swaza.life"
    },
]
const advisors = [
    {
        image: Prentice,
        name: "Dr. Prentice L. Tom, MD",
        college: "Harvard Medical School",
        role: "CMO, Vituity/CEP | Emergency Medicine; Large Health Systems Management",
        email: " "
    },
    {
        image: Naveen,
        name: "Dr. Naveen Jayakumar, MD",
        college: "UC Riverside",
        role: "Pulmonology and Critical Care",
        email: " "
    },
    {
        image: Michael,
        name: "Prof. Michael Snyder. PhD",
        college: "Stanford Medical School",
        role: "Chair, Dept. Genetics, Stanford University School of Medicine",
        email: " "
    },
    {
        image: Paul,
        name: "Dr. Paul Weiss, PhD",
        college: "UCLA",
        role: "California NanoSystems Institute Distinguished Professor of Bioengineering",
        email: " "
    },
]
function AboutUs() {
    return (
        <>
            <NavBar />

            <div className={classes.Header}>Team</div>
            <div className={classes.CardContainer} tabIndex="0">
                {team.map((item) => (
                    <div className={classes.Card} tabIndex="0" onClick="">
                        <img src={item.image} alt={item.header} tabIndex="0" />
                        <div className={classes.CardHeader}>{item.name}</div>
                        <div className={classes.Description}>{item.role}</div>
                        <div className={classes.ViewOnHover} tabIndex="0">
                            {String(item.email).length ? <div className={classes.ViewOnHoverDetails}>
                                <img className={classes.CardDetailsVector} src={EmailVector} alt="email" />
                                <div>{item.email}</div>
                            </div> : <div className={classes.ViewOnHoverDetails}></div>}
                            {String(item.college).length ? <div className={classes.ViewOnHoverDetails}>
                                <img className={classes.CardDetailsVector} src={CollegeVector} alt="college" />
                                <div>{item.college}</div>
                            </div> : <div className={classes.ViewOnHoverDetails}></div>}
                        </div>
                    </div>
                ))}
            </div>

            <div className={classes.Header} style={{ paddingTop: "5.3vw"}}>Advisors and Partners</div>
            <div className={classes.CardContainer}>
                {advisors.map((item) => (
                    <div className={classes.Card}>
                        <img src={item.image} alt={item.header} />
                        <div className={classes.CardHeader}>{item.name}</div>
                        <div className={classes.Description}>{item.role}</div>
                    </div>
                ))}
            </div>

            <div className={classes.Investors}>
                <img className={classes.InvestorsImageDesktop} src={InvestorsImage} alt="investors" />
                <img className={classes.InvestorsImageMobile} src={InvestorsImageMobile} alt="investors" />
                <div className={classes.InvestorsHeader}>Investors</div>
            </div>

            <Footer />

        </>
    );
}

export default AboutUs;
