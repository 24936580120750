import React from "react";
import classes from "./Footer.module.css";
import Logo from "../../assets/logo.svg";
import Facebook from "../../assets/facebook.png";
import LinkedIn from "../../assets/linkedin.png";
import Twitter from "../../assets/twitter.png";
import Instagram from "../../assets/instagram.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.MainContainer}>
      <div className={classes.Info}>
        <img className={classes.Logo} src={Logo} alt="logo" />
        <div className={classes.Navigation}>
          <div className={classes.Row}>
            <Link to="/"><div>Home</div></Link>
            <Link to="/our-technology"><div>Our Technology</div></Link>
            <Link to="/about-us"><div>About Us</div></Link>

          </div>
          <div className={classes.Row}>
            <Link to="/roadmap"><div>Pipeline & Roadmap</div></Link>
            <Link to="/news"><div>News</div></Link>
          </div>

        </div>
        <div className={classes.SocialLinks}>
          <div className={classes.Follow}>FOLLOW US</div>
          <div className={classes.SocialLogoContainer}>
            {/* <img className={classes.SocialLogo} src={Facebook} alt="facebook" /> */}
            {/* <img className={classes.SocialLogo} src={Instagram} alt="instagram" /> */}
            <a href="https://www.linkedin.com/company/swaza" target="_blank" rel="noreferrer">
              <img className={classes.SocialLogo} src={LinkedIn} alt="linkedin" />
            </a>
            {/* <img className={classes.SocialLogo} src={Twitter} alt="twitter" /> */}
          </div>

        </div>
      </div>
      <div className={classes.Contact}>
        <div className={classes.Copyright}>© 2022 Swaza Inc. All Rights reserved</div>
      </div>
    </div>
  );
};

export default Footer;
