import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import Swaza1 from "../../assets/technology-swaza-1.png"
import BubbleLeft from "../../assets/bubble-left.png"
import BubbleRight from "../../assets/bubble-right.png"
import TreatedImage1 from "../../assets/result-treated.png";
import UntreatedImage1 from "../../assets/result-untreated.png";
import ControlImage2 from "../../assets/result-control.png";
import TreatedImage2 from "../../assets/result-treated-2.png";
import NoTreatedImage2 from "../../assets/result-no-treatment.png";
import TechnologyHeaderImage from "../../assets/technology-banner.png"
import TechnologyWorkingImage from "../../assets/technology-nanoparticles.png"
import TechnologyApplicationImage from "../../assets/technology-applications.png"
import classes from "./Technology.module.css";
import PageHeader from "../../components/PageHeader/PageHeader";
import Footer from "../../components/Footer/Footer";

function Technology() {
    return (
        <>
            <NavBar />
            <PageHeader text="Our Technology" image={TechnologyHeaderImage} />

            <div className={classes.TechnologyContent}>
                <div className={classes.Section}>
                    <img className={classes.SectionImage} src={Swaza1} alt="Lung under microscope" />
                    <div className={classes.Bubble}>
                        <img className={classes.BubbleImage} src={BubbleLeft} alt="Bubble" />
                        <div className={classes.BubbleText}>
                            <div className={classes.BubbleTextHeader}>SWAZA-1 is a unique, IP protected novel formulation that addresses fluid and fibrotic barriers that block oxygen uptake at the lung surface</div>
                            <ul className={classes.BubbleTextPoints}>
                                <li>Features specific properties that enable dynamic exchange of O<sub>2</sub> and CO<sub>2</sub></li>
                                <li>Carries 20x concentration of oxygen compared to surrounding lung fluid</li>
                                <li>Radically improves oxygen delivery even in damaged lungs</li>
                                <li>Liquid nanoparticles move through fibrotic lesions and collagen rich fluid to move gases</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classes.SectionLeft}>
                    <div className={classes.Bubble}>
                        <img className={classes.BubbleImage} src={BubbleRight} alt="Bubble" />
                        <div className={classes.BubbleTextLeft}>
                            <div className={classes.BubbleTextHeader}>Swaza nanoparticles can repeatedly deliver  O<sub>2</sub> and remove CO<sub>2</sub></div>
                            <ul className={classes.BubbleTextLeftPoints}>
                                <li>Liquid nanoparticles shuttle through fluid and fibrotic tissue in the lung</li>
                                <li>Release oxygen and absorb carbon dioxide</li>
                                <li>Absorb oxygen at the air fluid interface</li>
                                <li>Self-buffering</li>
                            </ul>
                        </div>
                    </div>
                    <img className={classes.SectionLeftImage} src={TechnologyWorkingImage} alt="Swaza working" />
                </div>
                <div className={classes.Section}>
                    <img className={classes.SectionImage} src={TechnologyApplicationImage} alt="Lung" />
                    <div className={classes.Bubble}>
                        <img className={classes.BubbleImage} src={BubbleLeft} alt="Bubble" />
                        <div className={classes.BubbleText}>
                            <div className={classes.BubbleTextHeader}>Swaza has a wide range of therapeutic applications for breathing</div>
                            <ul className={classes.BubbleTextPoints}>
                                <li>Chronic obstructive pulmonary disease (COPD)</li>
                                <li>Respiratory Infections</li>
                                <li>Neuromuscular diseases impacting lung and airway function</li>
                                <li>Interstitial and Restrictive lung diseases</li>
                                <li>Asthma and allergic reactions</li>
                                <li>Suffocation, drowning, or smoke exposure</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classes.ResultSection}>
                    <div className={classes.SectionHeader}>Early success: Swaza significantly reduces lung injury in ARDS in mice</div>
                    <div className={classes.Gallery_1}>
                        <img src={TreatedImage1} alt="Treated"/>
                        <img src={UntreatedImage1} alt="No Treatment"/>
                    </div>
                    <div className={classes.SectionHeader}>Treatment with Swaza suppresses signals of inflammation with hypoxia</div>
                    <div className={classes.Gallery_2}>
                        <img src={ControlImage2} alt="control"/>
                        <img src={TreatedImage2} alt="Treated"/>
                        <img src={NoTreatedImage2} alt="No Treatment"/>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    );
}

export default Technology;
