import React from "react";
import NavBar from "../../components/NavBar/NavBar";
import PneumoniaImage from "../../assets/world-pneumonia-day.png"
import Swaza2 from "../../assets/swaza-2.png"
import Swaza3 from "../../assets/swaza-3.png"
import Swaza from "../../assets/swaza-4.png"
import RoadmapImage from "../../assets/roadmap2.png"
import RoadmapImageMobile from "../../assets/roadmap-mobile.png"
import RoadmapBackgroundImage from "../../assets/mobile-roadmap-background.png"
import classes from "./Roadmap.module.css";
import Footer from "../../components/Footer/Footer";
const items = [
    {
        image: PneumoniaImage,
        header: "SWAZA-1 : Nanoemulsion Breathing Aid",
        description: "First of its kind - draws oxygen from the air \nActively shuttles gases through fluid and fibrotic barriers at the lung surface"
    },
    {
        image: Swaza2,
        header: "SWAZA-2 : Oxygen Enriched Skin Healing",
        description: "Advanced biomaterial for burns and ulcers"
    },
    {
        image: Swaza3,
        header: "SWAZA-3 : Thermoreversible Enema",
        description: "Emergency oxygenation for severe trauma"
    },
    {
        image: Swaza,
        header: "SWAZA Drug Delivery solutions",
        description: "Enables delivery of molecules to the pulmonary system and across fibrotic barriers"
    },
]
function Roadmap() {
    return (
        <>
            <NavBar />

            <img className={classes.RoadmapBackground} src={RoadmapBackgroundImage} alt="roadmap" />
            <div className={classes.RoadmapHeader}>Platform Pipeline</div>
            <div className={classes.CardContainer}>
                {items.map((item) => (
                    <div className={classes.Card}>
                        <img src={item.image} alt={item.header} />
                        <div className={classes.Header}>{item.header}</div>
                        <div className={classes.Description}>{item.description}</div>
                    </div>
                ))}
            </div>
            <div className={classes.RoadmapHeader}>Roadmap to Phase II Clinical</div>
            <img className={classes.RoadmapImage} src={RoadmapImage} alt="roadmap" />
            <img className={classes.RoadmapImageMobile} src={RoadmapImageMobile} alt="roadmap" />

            <Footer />

        </>
    );
}

export default Roadmap;
