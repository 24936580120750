import React, { useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import NewsPhoto from "../../assets/newsPhoto.png";
import classes from "./News.module.css";
import Footer from "../../components/Footer/Footer";

const ARTICLES_PER_PAGE = 6;

const news = [
    {
        url: "https://fox8.com/business/press-releases/ein-presswire/659517902/swaza-inc-enters-into-a-partnership-with-bardas-drive-program-to-revolutionize-treatment-for-ards/",
        title: "Swaza Inc. Enters Into A Partnership with BARDA's DRIVe Program to Revolutionize Treatment for ARDS",
        description: "Swaza Inc., a biotechnology company building a novel nanotechnology oxygen therapeutics platform, has announced that it has entered into a partnership with the Biomedical Advanced Research and Development Authority's (BARDA) Division of Research, Innovation, and Ventures (DRIVe) program to develop a groundbreaking treatment for acute respiratory distress syndrome (ARDS).",
        date: "09 October 2023",
        image: NewsPhoto,
    },
];

function News() {
    const [page, setPage] = useState(0);
    return (
        <>
            <NavBar />
            <div className={classes.Header}>Featured News And Insights</div>
            <div className={classes.CardContainer}>
                {news.slice(page * ARTICLES_PER_PAGE, page * ARTICLES_PER_PAGE + ARTICLES_PER_PAGE).map((item) => (
                    <a href={item.url} target="_blank" rel="noreferrer" className={classes.CardFooterUrl} >
                        <div className={classes.Card}>
                            <img src={item.image} alt={item.title} />
                            <div className={classes.CardHeader}>{item.title}</div>
                            <div className={classes.CardDescription}>{item.description}</div>
                            <div className={classes.CardFooter}>
                                Read more
                                <div className={classes.CardFooterDate}>{item.date}</div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            {news.length > 6 ? <div className={classes.PageControl}>
                <button className={`${classes.NextButton} ${page * ARTICLES_PER_PAGE + ARTICLES_PER_PAGE <= news.length ? "" : classes.NextButtonDisabled}`} onClick={() => {
                    if (page * ARTICLES_PER_PAGE + ARTICLES_PER_PAGE <= news.length) setPage(page + 1)
                }}>Next &gt;</button>
                <button className={`${classes.NextButton} ${page === 0 ? classes.NextButtonDisabled : ""}`} onClick={() => {
                    if (page > 0) setPage(page - 1)
                }}>&lt; Back</button>
            </div> : null}
            <Footer />
        </>
    );
}

export default News;
